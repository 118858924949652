<script lang="ts" setup>
const props = defineProps({
  mobileResumeOpenned: {
    type: Boolean,
    default: false,
  },
})

const { step } = useCheckoutStep()

const showDetails = ref(props.mobileResumeOpenned)
</script>

<template>
  <div
    class="w-full h-full xl:w-[384px] xl:pb-10 xl:px-3 xl:pt-8"
  >
    <div class="flex flex-col">
      <div class="bg-white rounded-t-lg flex items-center justify-between p-4">
        <h3 class="text-primary-500 text-xl">
          Resumo do Pedido
        </h3>

        <button
          class="xl:hidden block"
          aria-label="Botão para expandir detalhe da compra."
          type="button"
          @click="showDetails = !showDetails"
        >
          <Icon
            name="mdi:chevron-down"
            class="w-6 h-6 duration-300 text-primary-400"
            :class="showDetails ? 'rotate-180' : ''"
          />
        </button>
      </div>

      <CartTotal :show-details="showDetails" :enable-remove-procedure="false" />
    </div>

    <div v-if="step.index >= 6" class="my-6 bg-white">
      <CartResumePersonalData />
      <CartResumeBillingAddress />
    </div>

    <CheckoutGuaranteedPurchaseInfo v-if="step.index !== 6" class="hidden xl:block xl:w-[360px] mt-6" />
  </div>
</template>
