<template>
  <div class="flex items-center flex-col gap-3 px-4 my-6">
    <p class="flex items-center gap-2 text-sm">
      <Icon name="mdi:cash-refund" class="min-w-[24px] min-h-[24px] w-6 h-6 text-information-500" />

      <span class="text-neutral-200">
        <NuxtLink to="/compra-garantida" class="text-information-500" no-prefetch>
          Cancele a qualquer momento,
        </NuxtLink>
        a partir da compra do exame com devolução integral do valor da compra.
      </span>
    </p>
    <p class="flex items-center gap-2 text-sm">
      <Img
        class="min-w-[24px] min-h-[24px] w-6 h-6"
        src="https://web-cdn.saudetrevo.com.br/icons/icone_compra_garantida.svg"
        alt="Icone de compra garantida"
        width="24"
        height="24"
      />
      <span class="text-neutral-200">
        <NuxtLink to="/compra-garantida" class="text-information-500" no-prefetch>
          Compra garantida,
        </NuxtLink>
        devolução automática caso você não realize ou cancele
        o exame dentro de 30 dias.
      </span>
    </p>
  </div>
</template>
